import React from "react"
import Helmet from 'react-helmet';
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import rehypeReact from "rehype-react"
import SummaryTable from "../components/summary-table"
import PropTypes from "prop-types"
import PostLink from "../components/post-link"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { "summary-table": SummaryTable }
}).Compiler

/*
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />

*/

const Tags = ({
  pageContext,
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
//  const { siteMetadata } = site
//  const { frontmatter, htmlAst } = markdownRemark
  const { edges, totalCount } = data.allMarkdownRemark
  const { tag } = pageContext
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`
  return (
    <Layout>
      <Helmet>
      <h1>{tagHeader}</h1>
      </Helmet>
      <div className="blog-post-container">
      <center><h1>{tagHeader}</h1>
      <h2>      <Link to="/tags">view all tags</Link></h2>
      </center>
      </div>
      <div className= "grids">
        {edges.map(({ node }) => {
          const { path } = node.frontmatter
          const { title } = node.frontmatter
          return (

          
              <PostLink key={node.id} post={node} />
         
          )
        })}
      </div>

 
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              path: PropTypes.string.isRequired
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            date(fromNow: true)
            path
            title
            thumbnail
            product
            description
            tags
          }
        }
      }
    }
  }
`
